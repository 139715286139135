<template>
	<div class="hx-order-five">
		
				<div align="left" class="left"  >
						<img src="../../assets/ysmimg/ysmorderoneimg.png" width="90px" height="110px" style="vertical-align: middle;" >
						<div>
							<h3>上传口扫</h3>
							<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;">请在此处上传口扫文件。请注意文件格式。</div>
							<div align="left"style="font-size: 0.9375rem;line-height: 1.875rem;" >您可以在<a href="./hxks.pdf" target="_blank" style="color: #0D8EA4;font-weight: bold;text-decoration: underline;">此处</a>找到有关图片的示例。</div>
							 <!-- <div align="left"style="font-size: 0.9375rem;line-height: 1.875rem;">重要提示：若加工产品所需模型数据、咬合重建数据不全加工后的矫治器因以上原因造成的佩戴不合适不享受质保服务。
							</div> --> 
							
							<div>
								<el-form :model="ruleForm" ref="ruleForm" :rules="rules">
									<!-- <el-form-item prop="jzType" label="点击此处做出选择" required>
										<el-select style="width: 100%;" @change="selectJZType" v-model="ruleForm.jzType" placeholder="请选择">
											<el-option v-for="item in jzTypes" :key="item.value" :label="item.label"
												:value="item.value">
											</el-option>
										</el-select>
									</el-form-item> -->
									<el-form-item prop="chengxiangDate" label="成像日期">
										<el-date-picker :picker-options="filerData.pickerOptions" type="date" placeholder="成像日期" v-model="ruleForm.chengxiangDate"
											style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy-MM-dd"></el-date-picker>
									</el-form-item>
									
								</el-form>
							</div>
							<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bold;">注意</span>：图像需近30天内</div>
							<div align="left"style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bold;">有效格式</span>：.stl、.obj</div>
							<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bold;">最小文件大小</span>：2MB</div>
							<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bold;">最大文件大小</span>：25MB</div>
						</div>
				</div>
				
				<div class="right">
					<!-- 上颌 -->
					<div class="shanghe" v-if="sh" style="margin-bottom: 40px;">
						<div style="position: relative;left: -13.25rem;">上传上颌口扫文件<span style="color: red;">*</span></div>
						<el-upload style="position: relative;" class="upload-demo" drag action='' ref="upload" :http-request="changeFile" multiple >
							<!-- <img v-show="shVisiable" class="sh" :src="shPicUrl" style="width: 100%; height: 100%;" /> -->
							<div v-show="shVisiable" style="width: 100%; height: 30px;line-height: 30px;position: absolute;top: 0px;background-color: #0D8EA4;color: white;" >{{shMess}}</div>
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处或<em>点击上传</em><br/>如修改请重新点击上传</div>
							<div class="el-upload__tip" slot="tip"></div>
						</el-upload>
						<!--  accept=".jpg,.png"
						:before-upload="beforeAvatarUpload" -->
					</div>
					<!-- 下颌 -->
					<div class="xiahe" v-if="xh" style="margin-bottom: 30px;">
						<div style="position: relative;left: -13.25rem;">上传下颌口扫文件<span style="color: red;">*</span></div>
						<el-upload style="position: relative;" class="upload-demo1" drag action='' multiple :http-request="changeFile1" >
							 	<!-- <img v-show="xhVisiable" class="xh" :src="xhPicUrl" style="width: 100%; height: 100%;" /> -->
							<div v-show="xhVisiable" style="width: 100%; height: 30px;line-height: 30px;position: absolute;top: 0px;background-color: #0D8EA4;color: white;" >{{xhMess}}</div>
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处或<em>点击上传</em><br/>如修改请重新点击上传</div>
							<div class="el-upload__tip" slot="tip"></div>
						</el-upload>
					</div>
					<div align="left" style="margin-bottom: 15px;border-bottom:2px solid gray;padding-bottom: 15px;"><span style="padding-left: 10px;"><span style="color: red;">*</span>为必填项</span></div>
					
					<!-- 咬合 -->
					<div class="yaohe" v-if="yh" :class="{'yhh':yhUpload==true}">
						<div  style="position: relative;left: -12.45rem;">上传咬合重建口扫文件</div>
						<el-upload style="position: relative;" class="upload-demo1 yhClass" drag action='' multiple :http-request="changeFile2" >
							<!-- 	<img v-show="yhVisiable" class="yh" :src="yhPicUrl" style="width: 100%; height: 100%;" /> -->
							<div v-show="yhVisiable" style="width: 100%; height: 30px;line-height: 30px;position: absolute;top: 0px;background-color: #0D8EA4;color: white;" >{{yhMess}}</div>
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处或<em>点击上传</em><br/>如修改请重新点击上传</div>
							<div class="el-upload__tip" slot="tip"></div>
						</el-upload>
						<!-- 进度条 -->
						<!-- <el-progress v-if="progressFlag" :percentage="loadProgress"></el-progress> -->
					</div>
					<div align="left" class="intru">
						<p style="color: black;font-weight: bolder;font-size: 18px;">咬合重建文件说明：</p>
						<p>1) 如您的瓠犀件需要咬合重建文件，请上传此口扫文件。</p>
						<p>2) II类错合的咬和重建需要下颌前伸至矫治目标位。一般前伸至目标位超过6mm的无法完成一次前伸，可根据矫治计划分步前伸。您本订单提交的咬合重建文件应是本次矫治所计划达到的目标位。</p>
						<p>3) III类错颌的咬和重建需要打开前牙区锁合2-3mm。</p>
						<p>4) 如有疑问，可联系我厂质控部负责人——何黎黎13256117868。</p>
					</div>
					
					
					<div style="text-align: right;margin-top: 50px;">
					<el-button @click="back" class="back"  type="button">返回</el-button>
					<el-button @click="nextStepKN" class="nextStep" type="button">下一步</el-button>
					</div>
				</div>
			
	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				percent: 0, // 动态显示进度条
				   progressFlag: false, // 关闭进度条
				filerData: {
				  pickerOptions: {
				    disabledDate(time) {
				      return time.getTime() > Date.now() - 8.64e6
				    }
				  },
				},
				shMess:'',
				xhMess:'',
				yhMess:'',
				shUpload:false,
				xhUpload:false,
				yhUpload:false,
				sh: true,
				xh: true,
				yh: true,
				shPicUrl:'',
				xhPicUrl:'',
				yhPicUrl:'',
				shVisiable:false,
				xhVisiable:false,
				yhVisiable:false,
				shText:'',
				ruleForm: {
					chengxiangDate: '',
					oid: '',
					jzType: '',
					
				},
				rules:{
						chengxiangDate:[
							{ required: true, message: '成像日期不能为空', trigger: 'blur' }
						]
				},
				jzTypes: [{
					value: '不带颌垫的上颌矫治器',
					label: '不带颌垫的上颌矫治器'
				}, {
					value: '不带颌垫的下颌矫治器',
					label: '不带颌垫的下颌矫治器'
				}, {
					value: '带颌垫的矫治器',
					label: '带颌垫的矫治器'
				}]
			}
		},
		methods: {
			 /* uploadVideoProcess(event, file, fileList) {
				 console.log(1);
			          this.progressFlag = true; // 显示进度条
			          this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
			          if (this.loadProgress >= 100) {
			              this.loadProgress = 100
			              setTimeout( () => {this.progressFlag = false}, 1000) // 一秒后关闭进度条
			          }
			      }, */
			
			//选择矫治器类型
			selectJZType(value) {
				if (value == "不带颌垫的上颌矫治器") { //不带颌垫的上颌矫治器
					this.xh = false;
					this.yh = false;
					this.sh = true;
				} else if (value == "不带颌垫的下颌矫治器") { //不带颌垫的下颌矫治器
					this.xh = true;
					this.yh = false;
					this.sh = false;
				} else if (value == "带颌垫的矫治器") { //带颌垫的矫治器
					this.xh = true;
					this.yh = true;
					this.sh = true;
				}
				this.$axios.get("/client/order/addHXType",{
					params:{
						oid:this.ruleForm.oid,
						leixing:value
					}
				}).then(res=>{
					
				}).catch(err=>{
					this.$alert("添加矫治器类型失败","提示");
				});
				
			},
			// beforeAvatarUpload(file) {
			//     console.log('file', file)
			//     let types = ['image/jpeg', 'image/jpg', 'image/png'];
			//     const isImage = types.includes(file.type);
			//     if (!isImage) {
			//         this.$message.error('上传图片只能是 .jpg、.jpeg、.dcm(DICOM)、.bmp、.tif、.tiff 格式!');
			//       }else{
			//         //this.uploadToken.key=`upload_pic_${Date.parse(new Date())}${file.name}`
			//       }
			// 	  const isLtSize = file.size / 1024 / 1024 < 5;
			// 	   if (!isLtSize) {
			// 	            this.$message.error('上传图片大小不能超过 1MB!');
			// 	       }
			//     },

			//上传上颌
			changeFile(file) {
				if(this.xhUpload==true||this.yhUpload==true){
					this.$alert("已有文件上传中,请稍候!");
					return;
				}
				this.shUpload=true;
				console.log(file.file.name);
				let formData = new FormData()
				formData.append('file', file.file) // 传文件
				formData.append('oid', this.ruleForm.oid)
				formData.append('type', 'ksShang')
				let self = this
				let config = {
					/* timeout:5600000, */
					headers: {
						"Content-Type": "multipart/form-data"
					}
				};
				/* this.$axios.post('client/order/uploadResourceFile', formData, config)
					.then(res => {
						if (res.data.code == 200) {
							this.$message({
								message:"上传成功",
								type:"success",
								duration:900
							});
							this.shVisiable=true;
							this.shText=file.file.name;
							//上传成功,图片回显
							this.shPicUrl=res.data.data.picUrl;
						}
					}).catch(err => {
						this.$alert("系统出现异常,请联系管理员!");
					}); */
					this.shVisiable=false;
					this.$axios({
					                    method:'post',
					                    headers: {
					                        "Content-Type": "multipart/form-data"
					                    },
					                    url:'client/order/uploadResourceFile',//自定义上传url
					                    data:formData,
					                    onUploadProgress: progressEvent => {
											console.log(progressEvent.lengthComputable);
											 if (progressEvent.lengthComputable) {
											                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
											                        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
											                     let percent=(progressEvent.loaded / progressEvent.total * 100) | 0
											                     file.onProgress({percent:percent});//调用uploader的进度回调
																 if(percent==100){
																	   this.shMess="文件已上传至服务器,资源解析中......";
																	   this.shVisiable=true;
																 }
											  }
					                         
					                    }
					                }).then((res)=>{
										console.log(res.data);
					                    if(res.data.code==200){
											let pros=document.getElementsByClassName("el-progress el-progress--line");
											for(let i=0;i<pros.length;i++){
												pros[i].style.display="none";
											}
										   this.shMess="资源上传解析完毕："+res.data.data.fileName;
					                       this.shVisiable=true;
										   this.shUpload=false;
											this.$message({
												message:"上传成功",
												type:"success",
												duration:900
											});
											
					                    }else{
					                        this.$message.error('上传失败');
					                    }
					                }).catch((err)=>{
					                    this.$message.error(err);
					                })
			},
			//上传下颌
			changeFile1(file) {
				if(this.shUpload==true||this.yhUpload==true){
					this.$alert("已有文件上传中,请稍候!");
					return;
				}
				this.xhUpload=true;
				let formData = new FormData()
				formData.append('file', file.file) // 传文件
				formData.append('oid', this.ruleForm.oid)
				formData.append('type', 'ksXia')
				let self = this
				let config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				};
				/* this.$axios.post('client/order/uploadResourceFile', formData, config)
					.then(res => {
						console.log(res);
						if (res.data.code == 200) {
							this.xhVisiable=true;
							//上传成功,图片回显
							this.xhPicUrl=res.data.data.picUrl;
							this.$message({
								message:"上传成功",
								type:"success",
								duration:900
							});
							
						}
					}).catch(err => {
						this.$alert("系统出现异常,请联系管理员!");
					}); */
					this.xhVisiable=false;
					this.$axios({
					                    method:'post',
					                    headers: {
					                        "Content-Type": "multipart/form-data"
					                    },
					                    url:'client/order/uploadResourceFile',//自定义上传url
					                    data:formData,
					                    onUploadProgress: progressEvent => {
											 if (progressEvent.lengthComputable) {
											                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
											                        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
											                     let percent=(progressEvent.loaded / progressEvent.total * 100) | 0
											                     file.onProgress({percent:percent});//调用uploader的进度回调
																 if(percent==100){
																 		this.xhMess="文件已上传至服务器,资源解析中......";
																 		this.xhVisiable=true;
																 }
											                    }
					                         
					                    }
					                }).then((res)=>{
										console.log(res.data);
					                    if(res.data.code==200){
											let pros=document.getElementsByClassName("el-progress el-progress--line");
											for(let i=0;i<pros.length;i++){
												pros[i].style.display="none";
											}
											 this.xhMess="资源上传解析完毕："+res.data.data.fileName;
					                       this.xhVisiable=true;
										   this.xhUpload=false;
											this.$message({
												message:"上传成功",
												type:"success",
												duration:900
											});
											
					                    }else{
					                        this.$message.error('上传失败');
					                    }
					                }).catch((err)=>{
					                    this.$message.error(err);
					                })
			},
			//上传咬合
			/* changeFile2(file) {
				let formData = new FormData()
				formData.append('file', file.file) // 传文件
				formData.append('oid', this.ruleForm.oid)
				formData.append('type', 'ksYao')
				let self = this
				let config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				};
				this.$axios.post('client/order/uploadResourceFile', formData, config)
					.then(res => {
						if (res.data.code == 200) {
							this.yhVisiable=true;
							//上传成功,图片回显
							this.yhPicUrl=res.data.data.picUrl;
							this.$message({
								message:"上传成功",
								type:"success",
								duration:900
							});
						}
					}).catch(err => {
						this.$alert("系统出现异常,请联系管理员!");
					});
			}, */
			changeFile2(file) {
				if(this.xhUpload==true||this.shUpload==true){
					this.$alert("已有文件上传中,请稍候!");
					return;
				}
				this.yhUpload=true;
				this.percent=0;
				let formData = new FormData()
				formData.append('file', file.file) // 传文件
				formData.append('oid', this.ruleForm.oid)
				formData.append('type', 'ksYao')
				let self = this
				let config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				};
				/* this.$axios.post('client/order/uploadResourceFile', formData, config)
					.then(res => {
						if (res.data.code == 200) {
							this.yhVisiable=true;
							//上传成功,图片回显
							this.yhPicUrl=res.data.data.picUrl;
							this.$message({
								message:"上传成功",
								type:"success",
								duration:900
							});
						}
					}).catch(err => {
						this.$alert("系统出现异常,请联系管理员!");
					}); */
					 this.yhVisiable=false;
					this.$axios({
					                    method:'post',
					                    headers: {
					                        "Content-Type": "multipart/form-data"
					                    },
					                    url:'client/order/uploadResourceFile',//自定义上传url
					                    data:formData,
					                    onUploadProgress: progressEvent => {
											 if (progressEvent.lengthComputable) {
											                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
											                        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
											                     let percent=(progressEvent.loaded / progressEvent.total * 100) | 0
											                     file.onProgress({percent:percent});//调用uploader的进度回调
																 if(percent==100){
																 		this.yhMess="文件已上传至服务器,资源解析中......";
																 		this.yhVisiable=true;
																 }
											                    }
					                         
					                    }
					                }).then((res)=>{
										console.log(res.data);
					                    if(res.data.code==200){
											let pros=document.getElementsByClassName("el-progress el-progress--line");
											for(let i=0;i<pros.length;i++){
												pros[i].style.display="none";
											}
											this.yhMess="资源上传解析完毕："+res.data.data.fileName;
					                        this.yhVisiable=true;
											this.yhUpload=false;
											this.$message({
												message:"上传成功",
												type:"success",
												duration:900
											});
											
					                    }else{
					                        this.$message.error('上传失败');
					                    }
					                }).catch((err)=>{
					                    this.$message.error(err);
					                })
			},
			//下一步
			nextStepKN() {
				/* if(this.ruleForm.jzType==''){
					this.$alert("请选择矫治器类型","提示");
					return;
				} */
				if(this.ruleForm.chengxiangDate==''){
					this.$alert("请选择成像日期","提示");
					return;
				}
				
				if((this.ruleForm.jzType=="不带颌垫的上颌矫治器")&&(this.shPicUrl==""||this.shPicUrl==null)){
						this.$alert("请上传上颌图片","提示");
						return;
				}else if((this.ruleForm.jzType=="不带颌垫的下颌矫治器")&&(this.xhPicUrl==""||this.xhPicUrl==null)){
						this.$alert("请上传下颌图片","提示");
						return;
				}else if(this.ruleForm.jzType=="带颌垫的矫治器"){
						if((this.shPicUrl==""||this.shPicUrl==null)){
							this.$alert("请上传上颌图片","提示");
							return;
						}else if(this.xhPicUrl==""||this.xhPicUrl==null){
							this.$alert("请上传下颌图片","提示");
							return;
						}else if(this.yhPicUrl==""||this.yhPicUrl==null){
								this.$alert("请上传咬合图片","提示");
								return;
						}
					}
					
					//上传矫治器类型
					// let formData2 = new FormData()
					// console.log("矫治器类型" + this.ruleForm.jzType);
					// formData2.append('date', this.ruleForm.jzType) // 传文件
					// formData2.append('oid', this.ruleForm.oid)
					// formData2.append('type', "jzqlx")
					// this.$axios.post("client/order/uploadResourceDate", formData2, {
					// 	headers: {
					// 		"Content-type": "application/json"
					// 	}
					// }).then(res => {
					// 	console.log(res);
					// 	if (res.data.code == 200) {
					// 		this.$message({
					// 			message:"添加矫治器类型成功",
					// 			type:"success",
					// 			duration:900
					// 		});
					// 		this.$router.push("/addorder/orderfive")
					// 	}
					// }).catch(err => {
					// 	this.$message({
					// 		message:"添加矫治器类型失败",
					// 		type:"error",
					// 		duration:900
					// 	});
					// });
					
				
				//上传日期
				let formData1 = new FormData()
				formData1.append('date', this.ruleForm.chengxiangDate) // 传文件
				formData1.append('oid', this.ruleForm.oid)
				formData1.append('type', "ksDate")
				
				this.$axios.post("client/order/uploadResourceDate", formData1, {
					headers: {
						"Content-type": "application/json"
					}
				}).then(res => {
					if (res.data.code != 200) {
						this.$alert("添加成像日期失败","提示");
					}else if(res.data.code == 200){
						// this.$alert("添加成像日期失败","提示");
						//设置返回路径
						this.$store.commit("setBackLocation","/hxmain/hxorder/hxorderfive");
						this.$store.commit('setHxStepIndex', 6);
						this.$router.push("/hxmain/hxorder/hxordersix");
						
					}
				}).catch(err => {
						this.$message("添加成像日期失败","提示");
				});
				
			},
			//返回
			back(){
				this.$store.commit("setHxStepIndex",4);
				this.$router.push("/hxmain/hxorder/hxorderfour");
			},
		},
		mounted: function() {
			this.$store.commit('setHxStepIndex', 5);
			//设置默认地址
			//设置默认地址
			sessionStorage.setItem("location","/hxmain/hxorder/hxorderfive")
			//获取当前添加的订单oid
			//获取oid
			let oid = sessionStorage.getItem("oid");
			//this.ruleForm.oid="5e63248b2006487ebdcb5ff381f1360b"; 
			// if(oid==null){
			// 	this.$alert("请填写患者信息","提示");
			// 	this.$router.push("/addorder/orderone");
			// }else{
			// 	this.ruleForm.oid=oid;
			// }
			//设置返回路径
			this.$store.commit("setBackLocation","/hxmain/hxorder/hxorderfour");
			document.getElementsByClassName("saveb")[0].setAttribute("class","saveb show");
			document.getElementsByClassName("print")[0].setAttribute("class","print die");
			document.getElementsByClassName("fh")[0].setAttribute("class","fh show");
		},
		created:function(){
			
			/* document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
			document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2"); */
			//如果是编辑订单要获取oid的值
			let oid=sessionStorage.getItem("oid");
		
			if(oid!="undefined"&&oid!=''&&oid!=null){
				this.ruleForm.oid=oid;
				this.$axios.get("/client/order/getOrderInfo",{
					params:{
						oid:this.ruleForm.oid
					}
				}).then(res=>{
					let ksDate=res.data.data.resource.ksDate;//口扫日期
					let jzqlx=res.data.data.resource.jzqlx;//矫治器类型
					let shUrl=res.data.data.resource.ksShang;//上颌
					let xhUrl=res.data.data.resource.ksXia;//下颌
					let yhUrl=res.data.data.resource.ksYao;//咬合
					//日期回显
					if(ksDate!=null){
						this.ruleForm.chengxiangDate=ksDate;
					}
					//矫治器类型回显
					if(jzqlx!=null){
						this.ruleForm.jzType=jzqlx;
						if (jzqlx == "不带颌垫的上颌矫治器") { //不带颌垫的上颌矫治器
							this.xh = true;
							this.yh = true;
							this.sh = true;
						} else if (jzqlx == "不带颌垫的下颌矫治器") { //不带颌垫的下颌矫治器
							this.xh = true;
							this.yh = true;
							this.sh = true;
						} else if (jzqlx == "带颌垫的矫治器") { //带颌垫的矫治器
							this.xh = true;
							this.yh = true;
							this.sh = true;
						}
					}
					//回显上颌
					if(shUrl!=null&&shUrl!=''){
						this.shPicUrl=shUrl;
						this.shVisiable=true;
						this.shMess=res.data.data.resource.ksShangName;
					}
					//回显下颌
					if(xhUrl!=null&&xhUrl!=''){
						this.xhPicUrl=xhUrl;
							this.xhVisiable=true;
							this.xhMess=res.data.data.resource.ksXiaName;
					}
					//回显咬合
					if(yhUrl!=null&&yhUrl!=''){
						this.yhPicUrl=yhUrl;
						this.yhVisiable=true;
							this.yhMess=res.data.data.resource.ksYaoName;
					}
				}).catch(err=>{
					this.$alert("系统出现异常,请联系管理员!");
				});
			}else{
				this.$alert('请填写患者信息', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.push("/hxmain/hxorder/hxorderone");
					}
				});
			}
			
		},
		destroyed:function(){
			
				
				//上传矫治器类型
				// let formData2 = new FormData()
				// console.log("矫治器类型" + this.ruleForm.jzType);
				// formData2.append('date', this.ruleForm.jzType) // 传文件
				// formData2.append('oid', this.ruleForm.oid)
				// formData2.append('type', "jzqlx")
				// this.$axios.post("client/order/uploadResourceDate", formData2, {
				// 	headers: {
				// 		"Content-type": "application/json"
				// 	}
				// }).then(res => {
				// 	console.log(res);
				// 	if (res.data.code == 200) {
				// 		this.$message({
				// 			message:"添加矫治器类型成功",
				// 			type:"success",
				// 			duration:900
				// 		});
				// 		this.$router.push("/addorder/orderfive")
				// 	}
				// }).catch(err => {
				// 	this.$message({
				// 		message:"添加矫治器类型失败",
				// 		type:"error",
				// 		duration:900
				// 	});
				// });
				
			
			//上传日期
			let formData1 = new FormData()
			formData1.append('date', this.ruleForm.chengxiangDate) // 传文件
			formData1.append('oid', this.ruleForm.oid)
			formData1.append('type', "ksDate")
			
			this.$axios.post("client/order/uploadResourceDate", formData1, {
				headers: {
					"Content-type": "application/json"
				}
			}).then(res => {
				if (res.data.code != 200) {
					this.$alert("添加成像日期失败","提示");
				}else if(res.data.code == 200){
					// this.$alert("添加成像日期失败","提示");
					//设置返回路径
					/* this.$store.commit("setBackLocation","/hxmain/hxorder/hxorderfive");
					this.$store.commit('setHxStepIndex', 6) */;
					/* this.$router.push("/hxmain/hxorder/hxordersix"); */
					
				}
			}).catch(err => {
					this.$message("添加成像日期失败","提示");
			});
			
			
		}
	}
</script>

<style scoped="scoped">
	.hx-order-five{
		width: 80%;
		display: flex;
		margin: 0px auto;
		
		margin-top: 3.125rem;
		/* padding-bottom: 5.125rem; */
		padding-bottom: 10px;
		justify-content: space-around;
	}
	
	h3{
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #0D8EA4;
		margin-bottom: 20px;
		font-size: 24px;
	}
	.left{
		display: flex;
		width: 30rem;
		position: relative;
		justify-content: flex-start;
	}
	.right{
	 width: 560px;
	}
	

	
	.xy {
		display: none;
	}
	/* 按钮 */
	.nextStep{
		background-color: #0D8EA4 !important;
		border-color: #0D8EA4 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}
	.back{
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #0D8EA4 !important;
		color: #0D8EA4 !important;
	}
	.intru{
		background-color: #F5F5FA !important;
		border-radius: 5px;
		padding: 5px 0px;
	}
	.intru p{
		margin: 7px !important;
	}
	.yhh{
		margin-bottom: 40px;
	}
</style>
